.custom-toggle {
    display: flex;
    align-self: center;
    justify-self: center;
}

.custom-toggle .react-toggle-track{
    background-color: #dddddd;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #eeeeee;
  }

.custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: #444444;
}

.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #111111;
}